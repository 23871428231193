<template>
  <div class="cf wih100 ht100 videosPageMaxBox">
    <div class="wih100">
      <div class="w600 jcsb ml200">
        <el-input placeholder="请输入播放地址" v-model="vUrl2"></el-input>
        <el-button @click="handClick">播放</el-button>
      </div>
    </div>
    <div class="jcsb h255">
      <div v-if="flag" class="pr mr100 tc">
  
        <video style="backgroundColor:#000;object-fit: cover;"  class="cf w400 h230" controls autoplay muted loop>
          <source :src="vUrl" looptype="video/mp4" />
        </video>
        <div class="pa bottom0 wih100 tc">video播放器</div>
      </div> 
      <div v-if="flag">
        <EasyPlayer class="w400 h225" :video-url="vUrl"></EasyPlayer>
        <div>EasyPlayer播放器</div>
      </div>
      <div v-if="flag" class="pr ml100 tc">
        <LivePlayer class="cf w400 h200" :videoUrl="vUrl" />
        <div class="pa bottom0 wih100 tc">LivePlayer播放器</div>
      </div>
      
     
    </div>
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
import EasyPlayer from "@easydarwin/easyplayer";
export default {
  data() {
    return {
      flag: true,
      vUrl: require("@/assets/videos/video1.mp4"),
      vUrl2: "http://32.128.6.46:10800/",
    };
  },

  methods: {
    handClick() {
      this.flag = false;
      this.vUrl = this.vUrl2;
      setTimeout(() => {
        this.flag = true;
      }, 20);
    },
  },
  components: { EasyPlayer, LivePlayer },
};
</script>

<style scoped lang="scss">
.videosPageMaxBox {
  display: flex;
  text-align: center;
  align-items: center;
  // justify: space-between;
  flex-wrap: wrap;
  position: relative;
  // top: -2%;
  background: rgb(99, 93, 93);
  .item {
    width: 50vw;
    height: 50%;
    .cpt-common-float-player {
      position: relative !important;
      margin: 0 !important;
      padding: 0 !important;
      /deep/ .title-box {
        position: absolute !important;
        width: 100%;
        top: 16px;
      }
      /deep/ .box {
        width: 100%;
        height: 100%;
        .reset {
          right: 1% !important;
        }
        .video-box {
          width: 100%;
          height: 100%;
          .video-wrapper {
            padding-bottom: 0 !important;
            position: unset !important;
            .vjs-tech {
              object-fit: fill;
            }
          }
        }
      }
    }
  }
}
</style>